.card-doc {
    background-color: #fff !important;
}

.card-doc:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.card-doc .doc-header {
    background-color: #164588;
    border-radius: 10px;
    color: #fff;
}

.card-doc .doc-header:hover {
    transition: ease-in 0.6s;
    border-radius: 50px;
}

.doc-item .title-doc {
    color: #33393f !important;
    font-size: 30px;
    font-weight: 600;
}

.doc-item .doc-link {
    color: #2b2c2c !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.doc-item .doc-link:hover {
    color: #164588 !important;
    font-weight: 500 !important;
    text-decoration: underline;
    transition: ease-in 0.1s !important;
}

.card-doc:hover .doc-header {
    transition: ease-in-out 0.6s;
    border-radius: 50px !important;
}