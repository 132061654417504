.form-group-margin {
    background-color: #193b6b;
    padding: 40px;
    border-radius: 50px;
}

.form-group-margin:hover {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
}

.form-group-margin .input-group .form-control {
    background-color: #ffffff;
    color: #21333e
}

.btn-career {
    border: 2px double #ffffff;
    border-radius: 40px;
    font-size: 18px;
    font-weight: 500;
}

.btn-career:hover {
    border: 2px double #ffffff;
    opacity: initial;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s;
    border-radius: 0%;
}