.footer-bg-new {
    background-color: #ebf5fb !important;
    /* background-color: #EBF5FB !important; */
}

.footer-title-1 p {
    color: #164588 !important;
    font-size: 19px !important;
    font-family: sans-serif;
    font-weight: 300;
}

.footer-title-1 p:hover {
    color: #2349f3 !important;
}

.footer-title-2 ul li a {
    color: #063b86 !important;
}

.footer-title-2 ul li a:hover {
    color: #0c4dd8 !important;
}