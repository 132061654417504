.img-1 {
    height: 200px;
}

@media (max-width: 768px) {
    .img-1 {
        height: 280px;
    }
}

@media (max-width: 999px) {
    .img-1 {
        height: 310px;
    }
}

@media (max-width: 367px) {
    .img-1 {
        height: 250px;
    }
}